import Acceso from "../models/acceso";
import { APIBack } from "../utilities/dominios/apiBack";

class ServicioAcceso {
    public static async iniciarSesion(objAcceso: Acceso): Promise<any> {
        const datosEnviar = {
            method: "POST",
            body: JSON.stringify(objAcceso),
            headers: { "Content-Type": "application/json; charset=UTF-8" }
        }
        const url = APIBack.URL + APIBack.INICIO_SESION;
        const respuesta = fetch(url, datosEnviar)
            .then((res) => res.json())
            .then((datos) => { return datos })
            .catch((miError) => { return miError })
        return respuesta;
    }
}
export default ServicioAcceso;