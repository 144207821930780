import DatosRegistro from "../models/datosRegistro";
import { APIBack } from "../utilities/dominios/apiBack";

class ServicioRegistro {

    public static async registrarUsuario(objRegistro: DatosRegistro): Promise<any> {
        const datosEnviar = {
            method: "POST",
            body: JSON.stringify(objRegistro),
            headers: { "Content-Type": "application/json; charset=UTF-8" }
        }

        const url = APIBack.URL + APIBack.REGISTRO;

        const respuesta = fetch(url, datosEnviar)
            .then((res) => res.json())
            .then((datos) => { return datos })
            .catch((miError) => { return miError });
        return respuesta;
    }

}
export default ServicioRegistro;