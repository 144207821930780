class Acceso {

    public codUusario: number;
    public nombreAcceso: string;
    public claveAcesso: string;

    constructor (cod: number, nom: string, cla: string){
        this.codUusario = cod;
        this.nombreAcceso = nom;
        this.claveAcesso = cla;
    }

}
export default Acceso;