import React, { useEffect, useState } from "react";
import Antropometry from "../../../models/antropometry";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";
import Players from "../../../models/players";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";

export const AntropometriaAdministrar = () => {
    
    const [arregloAntro, setArregloAntro] = useState<Antropometry[]>([]);
    const [cedulaBuscada, setCedulaBuscada] = useState<string>("");
    const [antroSeleccionado, setAntroSeleccionado] = useState<Antropometry | null>(null);
    const [jugadoraSeleccionada, setJugadoraSeleccionada] = useState<Players | null>(null);
    const [show, setShow] = useState(false);
    const [mensaje, setMensaje] = useState("");

    const handleClose = () => setShow(false);
    const navegacion = useNavigate();

    const buscarJugadorPorCedula = async (cedula: string) => {
        try {
            const respuesta = await ServicioPrivado.peticionGET(APIBack.UNA_JUGADORA + "/" + cedula);
            if (respuesta) {
                setJugadoraSeleccionada(respuesta);
            } else {
                crearMensaje("error", "No se encontró información de la jugadora.");
            }
        } catch (error) {
            console.error("Error al obtener la información de la jugadora", error);
            crearMensaje("error", "Error al buscar los datos de la jugadora.");
        }
    };

    const buscarAntropometria = async () => {
        try {
            const respuesta = await ServicioPrivado.peticionGET(APIBack.UNA_ANTROPOMETRIA + "/" + cedulaBuscada);
            if (Array.isArray(respuesta) && respuesta.length > 0) {
                setArregloAntro(respuesta);
                setShow(true);
                setMensaje(""); // Limpiar el mensaje
                buscarJugadorPorCedula(cedulaBuscada);
            } else {
                crearMensaje("error", "No hay formularios asociados a este número de documento.");
                setMensaje("No hay formularios asociados a este número de documento.");
                setArregloAntro([]);
            }
        } catch (error) {
            crearMensaje("error", "Error al buscar los datos.");
        }
    };

    useEffect(() => {
        if (arregloAntro.length > 0) {
            // Aquí
        }
    }, [arregloAntro]);

    const formatNumber = (value: number | undefined, decimals: number = 2): string => {
        if (value !== undefined && value !== null) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(value);
        }
        return 'No disponible';
    };

    const handleAddNewToma = () => {
        if (jugadoraSeleccionada) {
            const cedula = jugadoraSeleccionada.cedula;
            navegacion(`/dash/antroAdd?cedula=${cedula}`);
        }
    };

    return (
        <div className="">
            <h5 className="text-capitalize fst-italic fw-bolder">Administrar</h5>
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Antropometría</li>
                    <li className="active breadcrumb-item" aria-current="page">Administrar</li>
                </ol>
            </nav>
            <div className="card">
                <div className="px-4 py-3 mb-0">
                    <div className="row border-bottom">
                        <span className="col-md-6 fs-4 card-title">Antropometría - Administrar</span>
                        <span className="col-md-6 text-end">
                        </span>
                    </div>
                </div>
                <div className="card-body p-4">                  
                    {/* Campo para buscar por cédula */}
                    <div className="mb-0">
                        <Form>
                            <Form.Group className="mb-2" controlId="cedulaBuscada">
                                <Form.Control
                                    type="text"
                                    placeholder="Número de documento"
                                    value={cedulaBuscada}
                                    onChange={(e) => setCedulaBuscada(e.target.value)}
                                />
                            </Form.Group>
                            <Button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={buscarAntropometria}
                            >
                                Buscar
                            </Button>
                        </Form>
                    </div>

                    {/* Mensaje de error */}
                    {mensaje && (
                        <div className="alert alert-warning">
                            {mensaje}
                            {mensaje === "No hay formularios asociados a este número de documento." && (
                                <div className="mt-3">
                                    <Link to={`/dash/antroAdd?cedula=${cedulaBuscada}`}>
                                        <Button className="btn btn-success">Agregar</Button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="mt-4"></div>
                    {/* Mostrar información de la jugadora */}
                    {jugadoraSeleccionada && (
                        <div className="mb-4">
                            <h4>Información de la Jugadora</h4>
                            <p><strong>Nombre:</strong> {jugadoraSeleccionada.nombresJugadora} {jugadoraSeleccionada.apellidosJugadora}</p>
                            <p><strong>Cédula:</strong> {jugadoraSeleccionada.cedula}</p>
                            <p><strong>Último Período:</strong> {jugadoraSeleccionada.fechaUltimoPeriodo.toLocaleString()}</p>
                            <p><strong>Fecha Nacimiento:</strong> {jugadoraSeleccionada.fechaNacimiento.toLocaleString()}</p>
                        </div>
                    )}
                    
                    {/* Mostrar múltiples formularios de antropometría */}
                    {arregloAntro.length > 0 && (
                        <div className="table-container">
                            <table className="table table-bordered table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th>Mediciones</th>
                                        {arregloAntro.map((_, index) => (
                                            <th key={index}>Toma {index + 1}</th>
                                        ))}
                                        
                                        <th className="text-center">
                                        {/* El enlace se ha cambiado por un botón que usa la función handleAddNewToma */}
                                        <button onClick={handleAddNewToma} className="btn btn-success">
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Fila para mostrar la fecha de cada toma */}
                                    <tr>
                                        <th className="table-active text-center">Fecha Toma Antropometría</th>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && item.fechaTomaAntro.toLocaleString()}</td>
                                        ))}
                                        <td></td>
                                    </tr>

                                    {/* Medidas básicas */}
                                    <tr>
                                        <th className="table-active text-center">Medidas Básicas</th>
                                        {arregloAntro.map((_, index) => (
                                            <td key={index} className="table-active"></td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Peso Corporal (kg)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.peso)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Talla (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.talla)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Angulo Q (Grados°)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.anguloQ)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Envergadura (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.envergadura)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tren Inferior (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.trenInferior)}</td>
                                        ))}
                                        <td></td>
                                    </tr>

                                    {/* Pliegues cutáneos */}
                                    <tr>
                                        <th className="table-active text-center">Pliegues Cutáneos</th>
                                        {arregloAntro.map((_, index) => (
                                            <td key={index} className="table-active"></td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Subescapular (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.subescapular)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tricipital (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.tricipital)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Bicipital (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.bicipital)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Supracrestal (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.supracrestal)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Supraespinal (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.supraespinal)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Abdominal (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.abdominal)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Muslo Anterior (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.musloAnterior)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Pierna Medial (mm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.piernaMedial)}</td>
                                        ))}
                                        <td></td>
                                    </tr>

                                    {/* Perímetros */}
                                    <tr>
                                        <th className="table-active text-center">Perímetros</th>
                                        {arregloAntro.map((_, index) => (
                                            <td key={index} className="table-active"></td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Brazo Relajado (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.brazoRelajado)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Brazo Flexionado (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.brazoFlexionado)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Muslo Medial (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.musloMedial)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Pantorrilla (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.pantorrilla)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Cintura (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.cintura)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Cadera (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.cadera)}</td>
                                        ))}
                                        <td></td>
                                    </tr>

                                    {/* Diámetros */}
                                    <tr>
                                        <th className="table-active text-center">Diámetros</th>
                                        {arregloAntro.map((_, index) => (
                                            <td key={index} className="table-active"></td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Húmero (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.humero)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Muñeca (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.muñeca)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Fémur (cm)</strong></td>
                                        {arregloAntro.map((item, index) => (
                                            <td key={index}>{item && formatNumber(item.femur)}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}  