class Physical {

    public fechaTomafis: string;
    public jugadorasCedula: number;
    public rangoEdad: string;
    public distancia: number;
    public etapasCompletadas: number;
    public puntaje: string;
    public distCooper: number;
    public vomax: number;
    public puntajeCooper: string;

    constructor(fechaToma: string, rangoEdad: string, distancia: number, etapasCompletadas: number,
        puntaje:string, distCooper: number, vomax: number, puntajeCooper: string, jugadorasCed: number) {

        this.fechaTomafis = fechaToma;
        this.rangoEdad = rangoEdad;
        this.distancia = distancia;
        this.puntaje = puntaje;
        this.distCooper = distCooper;
        this.etapasCompletadas = etapasCompletadas;
        this.vomax = vomax;
        this.puntajeCooper = puntajeCooper;
        this.jugadorasCedula = jugadorasCed;
    }
}
export default Physical;