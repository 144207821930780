import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";


type propsVigilante = {children?: any};

export const Vigilante = ({children}: propsVigilante) => {

    if (localStorage.getItem("TOKEN_AUTORIZACION")) {
        const token: any = localStorage.getItem("TOKEN_AUTORIZACION");
        try {
            jwtDecode(token);
        } catch {
            return <Navigate to = "/login"/>
        }
    }else{
       return <Navigate to="/login" />
    }

    return children? children: <Outlet/>;
}