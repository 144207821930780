class DatoSesion{
    public id:number;
    public nombresUsuario:string;
    public apellidosUsuario:string;
    public nombreAcceso:string;

    constructor(cod:number, nomU: string, apeU:string, nomA:string){
        this.id=cod;
        this.nombresUsuario=nomU;
        this.apellidosUsuario=apeU;
        this.nombreAcceso=nomA;
    }
}
export default DatoSesion;