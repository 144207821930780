import { Button, Nav, NavItem } from 'reactstrap';
import usuario from '../../assets/images/users/user5.jpg';
import { useState } from 'react';
import { OPCIONES_MENU } from '../utilities/dominios/opcionesMenu';
import { Link, useLocation} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import DatosRegistro from '../models/datosRegistro';
import userLogin from '../../assets/images/users/userLogin.png';

export const MenuLateral = () => {

    const showMobilemenu = () => {
        document.getElementById("sidebarArea")?.classList.toggle("showSidebar");
    }    

    const [arregloOpciones] = useState(OPCIONES_MENU);
    const location = useLocation();

    const token: any = localStorage.getItem("TOKEN_AUTORIZACION");
    const objRecibido: any = jwtDecode(token);

    const datosRegistro = new DatosRegistro (
        objRecibido.cedula,
        objRecibido.nombresUsuario, 
        objRecibido.apellidosUsuario,
        objRecibido.nombreAcceso,
        objRecibido.rolUsuario,
        objRecibido.claveAcceso
    )

    return (
        <aside id="sidebar" className="sidebar-area">
            <div className="sidebarBox shadow bg-bg-dark fixedSidebar">
                <div className="d-flex">
                <div className="py-3 px-4 d-flex align-items-center border-white border-bottom-1">
                    <img src={userLogin} alt="profile" width="30"/>
                    <div className="ms-3 opacity-75 text-truncate text-white">{datosRegistro.nombreAcceso}</div></div>
                </div>
                <Button
                    color="white"
                    className="ms-auto text-white d-lg-none"
                    onClick={() => showMobilemenu()}
                >
                    <i className="bi bi-x"></i>
                </Button>
                <div className="p-3">
                    <Nav vertical className="sidebarNav">
                        {
                            arregloOpciones.map((opcion, indice) => 
                                opcion?.children?.length ?
                                    <NavItem key={indice} className="sidenav-bg">
                                        <Link to={opcion.ruta}
                                        className="nav-link collapsesd py-3"
                                        data-bs-target = {"#menu"+indice}
                                        data-bs-toggle = "collapsed" /*collapsed*/ 
                                        >
                                            <i className={opcion.icono}></i>
                                            <span className= "ms-3 d-inline-block">{opcion.titulo}</span>
                                        </Link>
                                        <ul id={"menu" + indice }
                                        className="nav-content collapsed"
                                        dat-bs-parent ="#sidebar-nav">
                                            {opcion?.children.map((subOpcion:any, subIndice:number)=>(
                                                <li key={"sub"+subIndice}
                                                style={{listStyle:"none"}}>
                                                    <Link className={
                                                        location.pathname==subOpcion.ruta
                                                        ?"active nav-link fs-6"
                                                        :"nav-link fs-6"
                                                    }
                                                    to={subOpcion.ruta}>
                                                        <span>{subOpcion.titulo}</span>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                        
                                    </NavItem>
                                    :
                                    <NavItem key={indice}  className="sidenav-bg">
                                        <Link to={opcion.ruta}
                                            className = {
                                                location.pathname == opcion.ruta
                                                ?"active nav-link py-3"
                                                :"nav-link py-3"
                                            }
                                        >
                                            <i className={opcion.icono}></i>
                                            <span className="ms-3 d-inline-block">{opcion.titulo}</span>
                                        </Link>
                                    </NavItem>

                            )
                            
                        }
                    </Nav>
                </div>
            </div>


   </aside>
    )
}