class DatosRegistro {
    public cedulaUsuario: number;
    public nombresUsuario: string;
    public apellidosUsuario: string;
    public rolUsuario: string;
    public nombreAcceso: string;
    public claveAcceso: string;

    constructor(ced:number, nom: string, apell: string, nomA: string, rol: string, cla: string) {
        this.cedulaUsuario = ced;
        this.nombresUsuario = nom;
        this.apellidosUsuario = apell;
        this.rolUsuario = rol;
        this.nombreAcceso = nomA;
        this.claveAcceso = cla;
    }

}
export default DatosRegistro;