import { APIBack } from "../utilities/dominios/apiBack";

class ServicioPrivado {

    public static async peticionGET(urlServicio: string){
        const bearerToken = "Bearer " +String(localStorage.getItem("TOKEN_AUTORIZACION"));

        const datosEnviar = {
            method: 'GET',
            headers:  {"Content-Type": "application/json; charset=UTF-8", authorization: bearerToken}
        }

        const url = APIBack.URL+ urlServicio;
        const respuesta = fetch(url, datosEnviar).then((respuesta) => respuesta.json())
            .then((datos)=> {return datos})
            .catch((miError)=> {return miError});

        return respuesta;
    }

    public static async peticionPOST(urlServicio: string, objRegistro: any){
        const bearerToken = "Bearer " + String(localStorage.getItem("TOKEN_AUTORIZACION"));
        const datosEnviar = {
            method: 'POST',
            headers:  {"Content-Type": "application/json; charset=UTF-8", authorization: bearerToken},
            body: JSON.stringify(objRegistro)
        }

        const url = APIBack.URL+ urlServicio;

        const respuesta = fetch(url, datosEnviar).then((respuesta) => respuesta.json())
            .then((datos)=> {return datos})
            .catch((miError)=> {return miError});

        return respuesta;
    }

    public static async peticionDELETE(urlServicio: string){
        const bearerToken = "Bearer " +String(localStorage.getItem("TOKEN_AUTORIZACION"));

        const datosEnviar = {
            method: 'DELETE',
            headers:  {"Content-Type": "application/json; charset=UTF-8", authorization: bearerToken}
        }

        const url = APIBack.URL+ urlServicio;
        const respuesta = fetch(url, datosEnviar).then((respuesta) => respuesta.json())
            .then((datos)=> {return datos})
            .catch((miError)=> {return miError});

        return respuesta;
    }

    public static async peticionPUT(urlServicio: string, objActualizar: any){
        const bearerToken = "Bearer " + String(localStorage.getItem("TOKEN_AUTORIZACION"));

        const datosEnviar = {
            method: 'PUT',
            headers:  {"Content-Type": "application/json; charset=UTF-8 ", authorization: bearerToken},
            body: JSON.stringify(objActualizar)
        }

        const url = APIBack.URL+ urlServicio;
        const respuesta = fetch(url, datosEnviar).then((respuesta) => respuesta.json())
            .then((datos)=> {return datos})
            .catch((miError)=> {return miError});

        return respuesta;
    }
   
}

export default ServicioPrivado;