class Technique {

    public fechaTomatec: string;
    public jugadoraCedula: number;
    public definicion: string;
    public dominioBalon: string;
    public efectividadPase: string;
    public tomaDecisiones: string;

    constructor(fechaToma: string, definicion: string, dominio: string,
        efectividadPase: string, tomaDecisiones: string, jugadoraCed: number) {
        this.fechaTomatec = fechaToma;
        this.definicion = definicion;
        this.dominioBalon = dominio;
        this.efectividadPase = efectividadPase;
        this.tomaDecisiones = tomaDecisiones;
        this.jugadoraCedula = jugadoraCed;
    }

}
export default Technique;