import React, { useState } from "react";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import DatePicker from "react-date-picker";
import Physical from "../../../models/physical";

type fecha = Date | any;

export const FisicoCrear = () => {
    type frmHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);

    let { fechaTomafis, jugadorasCedula, rangoEdad, distancia, etapasCompletadas, puntaje,
        distCooper, vomax, puntajeCooper, dobleEnlace, objeto } = useFormulario<Physical>
            (new Physical("", "", 0, 0, "", 0, 0, "", 0));

    const [fechasTomaFis, setFechasTomaFis] = useState<fecha>(new Date());
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cedula = queryParams.get('cedula') || '';
    const [jugadorasCedulaAdd, setJugadorasCedulaAdd] = useState<string>(cedula);
    const navegacion = useNavigate();

    // Nuevas variables de estado
    const [testSeleccionado, setTestSeleccionado] = useState<string>("yoyo");
    const [rangoEdadSel, setRangoEdadSel] = useState<string>("18-25");
    const [distanciaYoyo, setdistanciaYoyo] = useState<number>(0);
    const [distanciaCooper, setdistanciaCooper] = useState<number>(0);
    const [etapascompletadas, setEtapasCompletadas] = useState<number>(0);
    const [vo2max, setVo2max] = useState<number | null>(null);
    const [puntajeRendimiento, setPuntajeRendimiento] = useState<string>("");
    const [puntajecooper, setPuntajeCooper] = useState<string>("");


    // Función para calcular puntaje en el test de Yoyo
    const calcularPuntajeYoyo = () => {
        let puntajeY = "";
        if (rangoEdadSel === "18-25") {
            puntajeY = distanciaYoyo < 8 ? "Bajo" :
                distanciaYoyo <= 14 ? "Promedio" :
                    distanciaYoyo <= 18 ? "Bueno" : "Excelente";
        } else if (rangoEdadSel === "26-35") {
            puntajeY = distanciaYoyo < 7 ? "Bajo" :
                distanciaYoyo <= 13 ? "Promedio" :
                    distanciaYoyo <= 16 ? "Bueno" : "Excelente";
        } else if (rangoEdadSel === "36-45") {
            puntajeY = distanciaYoyo < 6 ? "Bajo" :
                distanciaYoyo <= 12 ? "Promedio" :
                    distanciaYoyo <= 15 ? "Bueno" : "Excelente";
        } else if (rangoEdadSel === "46-55") {
            puntajeY = distanciaYoyo < 5 ? "Bajo" :
                distanciaYoyo <= 10 ? "Promedio" :
                    distanciaYoyo <= 13 ? "Bueno" : "Excelente";
        }
        setPuntajeRendimiento(puntajeY);

    };

    // Función para calcular VO2máx y puntaje en el test de Cooper
    const calcularPuntajeCooper = () => {
        const vo2 = (distanciaCooper - 504.9) / 44.73;
        setVo2max(vo2);
        let puntajeC = "";
        if (distanciaCooper < 19) {
            puntajeC = "Bajo";
        } else if (distanciaCooper <= 22) {
            puntajeC = "Promedio";
        } else if (distanciaCooper <= 25) {
            puntajeC = "Bueno";
        } else {
            puntajeC = "Excelente";
        }
        setPuntajeCooper(puntajeC);
    };

    const enviarFormulario = async (formulario: frmHtml) => {
        formulario.preventDefault();
        setEnProceso(true);
        const miFrm = formulario.currentTarget;
        miFrm.classList.add("was-validated");
        if (miFrm.checkValidity() === false) {
            formulario.stopPropagation();
        } else {
            const fechaTomaFisFormateada = fechasTomaFis.toISOString().slice(0, 10);
            objeto.fechaTomafis = fechaTomaFisFormateada;
            objeto.jugadorasCedula = parseInt(jugadorasCedulaAdd);
            objeto.puntaje = puntajeRendimiento;
            objeto.puntajeCooper = puntajecooper;
            const resultado = await ServicioPrivado.peticionPOST(APIBack.AGREGAR_FISICO, objeto);
            if (resultado) {
                crearMensaje("success", "Registro físico exitoso");
                navegacion("/dash/fisicoAdmin");
            } else {
                crearMensaje("error", "Fallo al registrar formulario físico");
                limpiarCajas(miFrm);
            }
        }
        setEnProceso(false);
    };

    const limpiarCajas = (formulario: HTMLFormElement) => {
        formulario.reset();
        formulario.rangoEdad.value = "";
        formulario.distancia.value = 0;
        formulario.etapasCompletadas.value = 0;
        formulario.puntaje.value = "";
        formulario.distCooper.value = "";
        formulario.vomax.value = "";
        setVo2max(null);
        formulario.classList.remove("was-validated");
    };

    return (
        <div>
            <h5 className="text-capitalize fst-italic fw-bolder">Crear</h5>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Físico</li>
                    <Link to="/dash/fisicoAdmin" className="text-decoration-none link-info breadcrumb-item">Administrar</Link>
                    <li className="active breadcrumb-item" aria-current="page">Crear</li>
                </ol>
            </nav>

            <div className="m-4 card">
                <div className="bg-light card-body"><h4 className="mb-0 card-title">Información Físico</h4></div>
                <div className="card-body">
                    <Form validated={enProceso} onSubmit={enviarFormulario}>
                        <div className="row">
                            <div className="mb-4">
                                <Form.Group controlId="fechasTomaFis">
                                    <Form.Label><span className="rojito">*</span>Tomado en</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        value={fechasTomaFis}
                                        onChange={date => setFechasTomaFis(date)}
                                        format="yyyy/MM/dd"
                                        clearIcon={null}
                                        calendarIcon={null}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-4">
                                <Form.Group controlId="jugadorasCedulaAdd">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>C.C Jugadora
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="jugadorasCedulaAdd"
                                        value={jugadorasCedulaAdd}
                                        onChange={(e) => setJugadorasCedulaAdd(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            {/* Selección de test */}
                            <Form.Group className="mb-4">
                                <Form.Check
                                    type="radio"
                                    label="Test de Yoyo"
                                    value="yoyo"
                                    checked={testSeleccionado === "yoyo"}
                                    onChange={(e) => setTestSeleccionado(e.target.value)}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Test de Cooper"
                                    value="cooper"
                                    checked={testSeleccionado === "cooper"}
                                    onChange={(e) => setTestSeleccionado(e.target.value)}
                                />
                            </Form.Group>

                            {/* Campos para el test de Yoyo */}
                            {testSeleccionado === "yoyo" && (
                                <>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Rango de edad</Form.Label>
                                        <Form.Select value={rangoEdadSel} onChange={e => setRangoEdadSel(e.target.value)}>
                                            <option value="18-25">18-25</option>
                                            <option value="26-35">26-35</option>
                                            <option value="36-45">36-45</option>
                                            <option value="46-55">46-55</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Distancia total recorrida (m)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={distanciaYoyo}
                                            onChange={e => setdistanciaYoyo(Number(e.target.value))}
                                            placeholder="Ingresa la distancia"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Número de etapas completadas</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={etapascompletadas}
                                            onChange={e => setEtapasCompletadas(Number(e.target.value))}
                                            placeholder="Ingresa el número de etapas"
                                        />
                                    </Form.Group>
                                    <button type="button" onClick={calcularPuntajeYoyo} className="mb-4 btn btn-info">Calcular Puntaje</button>
                                    {puntajeRendimiento && <div className="mb-4">Puntaje de Rendimiento: {puntajeRendimiento}</div>}
                                </>
                            )}

                            {/* Campos para el test de Cooper */}
                            {testSeleccionado === "cooper" && (
                                <>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Distancia total recorrida (m)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={distanciaCooper}
                                            onChange={e => setdistanciaCooper(Number(e.target.value))}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Tiempo (minutos)</Form.Label>
                                        <Form.Control type="number" value={12} readOnly />
                                    </Form.Group>
                                    <button type="button" onClick={calcularPuntajeCooper} className="mb-4 btn btn-info">Calcular Puntaje y VO2máx</button>
                                    {vo2max !== null && (
                                        <div className="mb-4">
                                            <h5>VO2máx: {vo2max.toFixed(2)} ml/kg/min</h5>
                                            <h5>Puntaje de Rendimiento: {puntajecooper}</h5>
                                        </div>
                                    )}
                                </>
                            )}

                            {/* Otros campos del formulario... */}
                            <div className="border-top gap-2 d-flex align-items-center card-body">
                                <button type="submit" className="btn btn-primary">Registrar</button>
                                <button type="button" className="btn btn-danger ml-2" onClick={(e) => {
                                    e.preventDefault();
                                    navegacion("/dash/fisicoAdmin");
                                }}>Cancelar</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

