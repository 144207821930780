import { Suspense } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { RuteoPrincipal } from './app/routes/ruteo-principal';
import { ToastContainer } from 'react-toastify';

/*Soporte de renderizado*/
const cargando = () =>(
  <div className='d-flex justify-content-center'>
    <div className="mt-3">
      <button className='btn btn-primary' disabled>
        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden="true"></span>
        Cargando .....
      </button>
    </div>
  </div>
)

function App() {
  return (
    <BrowserRouter>
    <ToastContainer/>
      <Suspense fallback={cargando()}>
        <RuteoPrincipal />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
