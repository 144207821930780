import { lazy } from "react"
import { Routes, Route } from "react-router-dom"
import { Bienvenida } from "../contenedor/bienvenida"
import { Error } from "../components/shared/error";
import { JugadoraAdministrar } from "../components/private/players/jugadoraAdministrar";
import { JugadoraActualizar } from "../components/private/players/jugadoraActualizar";
import { JugadoraCrear } from "../components/private/players/jugadoraCrear";
import { AntropometriaCrear } from "../components/private/antropometry/antroCrear";
import { AntropometriaAdministrar } from "../components/private/antropometry/antroAdministrar";
import { TecnicaCrear } from "../components/private/technique/tecnicaCrear";
import { TecnicaAdministrar } from "../components/private/technique/tecnicaAdministrar";
import { FisicoCrear } from "../components/private/physical/fisicoCrear";
import { FisicoAdministrar } from "../components/private/physical/fisicoAdministrar";
import { AnalisisYGraficas } from "../components/private/graficas/graficas";

const LazyInicio = lazy(() => import('../contenedor/bienvenida').then(() => ({ default: Bienvenida })));
const LazyError = lazy(() => import('../components/shared/error').then(() => ({ default: Error })));

/* Ruteo - Jugadora */
const LazyJugadoraAdmin = lazy(() => import('../components/private/players/jugadoraAdministrar')
.then(() => ({ default: JugadoraAdministrar })));

const LazyJugadoraCrear= lazy(() => import('../components/private/players/jugadoraCrear')
.then(() => ({ default: JugadoraCrear })));

const LazyJugadoraActualizar= lazy(() => import('../components/private/players/jugadoraActualizar')
.then(() => ({ default: JugadoraActualizar })));

/* Ruteo - Antropometría */
const LazyAntroCrear = lazy(() => import('../components/private/antropometry/antroCrear')
.then(() => ({ default: AntropometriaCrear })));

const LazyAntroAdmin = lazy(() => import('../components/private/antropometry/antroAdministrar')
.then(() => ({ default: AntropometriaAdministrar })));

/* Ruteo - Técnica */
const LazyTecnicaCrear = lazy(() => import('../components/private/technique/tecnicaCrear')
.then(() => ({ default: TecnicaCrear })));

const LazyTecnicaAdmin = lazy(() => import('../components/private/technique/tecnicaAdministrar')
.then(() => ({ default: TecnicaAdministrar })));

/* Ruteo - Físico */
const LazyFisicoCrear = lazy(() => import('../components/private/physical/fisicoCrear')
.then(() => ({ default: FisicoCrear })));

const LazyFisicoAdmin = lazy(() => import('../components/private/physical/fisicoAdministrar')
.then(() => ({ default: FisicoAdministrar })));

/*Ruteo Gráficas*/
const LazyGraficas = lazy(() => import('../components/private/graficas/graficas')
.then(() => ({ default: AnalisisYGraficas })));


export const RuteoInterno = () => {
    return (
        <Routes>

            {/* Jugadoras */}
            <Route path="/playersAdmin" element={<LazyJugadoraAdmin />} />
            <Route path="/playersAdd" element={<LazyJugadoraCrear />} />
            <Route path="/playersEdit/:cedula" element={<LazyJugadoraActualizar />} />

            {/* Antropometría */}
            <Route path="/antroAdd" element={<LazyAntroCrear />} />
            <Route path="/antroAdmin" element={<LazyAntroAdmin />} />

            {/* Técnica */}
            <Route path="/tecnicaAdd" element={<LazyTecnicaCrear />} />
            <Route path="/tecnicaAdmin" element={<LazyTecnicaAdmin />} />

            {/* Físico */}
            <Route path="/fisicoAdd" element={<LazyFisicoCrear />} />
            <Route path="/fisicoAdmin" element={<LazyFisicoAdmin />} />

            {/* Gráficas */}
            <Route path="/grafAdmin" element={<LazyGraficas />} />


            {/* Obligatorias */}
            <Route path="/" element={<LazyInicio />} />
            <Route path="*" element={<LazyError />} />
        </Routes>
    )

}