export const APIBack = {
  URL: "http://5.161.111.47:3300",
  INICIO_SESION: "/api/public/access/signin",
  REGISTRO: "/api/public/register/add",

  /* URL para los servicios privados */

  /* URL Jugadoras */
  AGREGAR_JUGADORA: "/api/private/player/add",
  OBTENER_JUGADORA: "/api/private/player/list",
  EDITAR_JUGADORA: "/api/private/player/update",
  BORRAR_JUGADORA: "/api/private/player/delete",
  UNA_JUGADORA: "/api/private/player/find",

  /* URL Antropometria */
  OBTENER_ANTROPOMETRIA: "/api/private/antropometry/list",
  AGREGAR_ANTROPOMETRIA: "/api/private/antropometry/add",
  UNA_ANTROPOMETRIA: "/api/private/antropometry/find",
  EDITAR_ANTROPOMETRIA: "/api/private/antropometry/update",

  /* URL Fisico */
  AGREGAR_FISICO: "/api/private/physical/add",
  OBTENER_FISICO: "/api/private/physical/list",
  EDITAR_FISICO: "/api/private/physical/update",
  UNA_FISICO: "/api/private/physical/find",

  /* URL Tecnica */
  AGREGAR_TECNICA: "/api/private/technique/add",
  OBTENER_TECNICA: "/api/private/technique/list",
  EDITAR_TECNICA: "/api/private/technique/update",
  UNA_TECNICA: "/api/private/technique/find",

  /*Graficas*/
  VER_GRAFICAS: "/api/private/graficas/mostrar",

  /*Minería*/
  VER_MINERIA: "/api/private/dataMining/mineria",

  PREDICCION: "http://localhost:8000/predict",
  PRONOSTICO: "http://localhost:8000/pronostico",
  VISUALIZAR_TENDENCIAS: "http://localhost:8000/visualizar/tendencias",
  VISUALIZAR_COMPARATIVA: "http://localhost:8000/visualizar/comparativa",
  DATOS_HISTORICOS: "/api/private/datos/historicos",
};
