import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { APIBack } from "../../../utilities/dominios/apiBack";
import ServicioPrivado from "../../../services/servicioPrivado";
import { Button, Modal } from "react-bootstrap";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import Players from "../../../models/players";

export const JugadoraAdministrar = () => {


    const [arregloJugadora, setArregloJugadora] = useState<Players[]>([]);

    const [jugadoraSeleccionada, setJugadoraSeleccionada] = useState<Players>(new Players(0, "", "", "", "", "", "", ""));
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const obtenerJugadora = async () => {
        try {
            const respuesta = await ServicioPrivado.peticionGET(APIBack.OBTENER_JUGADORA);
            if (Array.isArray(respuesta)) {
                const jugadorasConFechas = respuesta.map((jugadora: any) => ({
                    ...jugadora,
                    fechaNacimiento: new Date(jugadora.fechaNacimiento),
                    fechaUltimoPeriodo: new Date(jugadora.fechaUltimoPeriodo)
                }));
                setArregloJugadora(jugadorasConFechas);
            } else {
                console.error("La respuesta de la API no es un arreglo", respuesta);
                setArregloJugadora([]);
            }
        } catch (error) {
            console.error("Error al obtener jugadoras", error);
            setArregloJugadora([]);
        }
    };

    const eliminarJugadora = async (codigo: number) => {
        try {
            const resultado = await ServicioPrivado.peticionDELETE(APIBack.BORRAR_JUGADORA + "/" + codigo);
            if (resultado.respuesta) {
                crearMensaje("success", "La jugadora " + jugadoraSeleccionada.nombresJugadora + " " + jugadoraSeleccionada.apellidosJugadora + " ha sido eliminada");
                // Actualiza la lista de jugadoras después de eliminar
                obtenerJugadora();
            } else {
                crearMensaje("error", "Fallo al eliminar jugadora");
            }
        } catch (error) {
            console.error('Error al eliminar jugadora:', error);
            crearMensaje("error", "Fallo al eliminar jugadora");
        }
    };

    useEffect(() => {
        obtenerJugadora();
    }, []);

    return (
        <div className="">
            <h5 className="text-capitalize  fst-italic fw-bolder">Administar</h5>
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Jugadora</li>
                    <li className="active breadcrumb-item" aria-current="page">Administrar</li>

                </ol>
            </nav>
            <div className="card" >
                <div className="px-4 py-3 mb-0" >
                    <div className="row border-bottom">
                        <span className="col-md-6 fs-4 card-title">Listado de jugadoras en el sistema</span>
                        <span className="col-md-6 text-end">
                            <Link to="/dash/playersAdd" className="btn btn-sm btn-success m-2"> Agregar
                                &nbsp;<i className="fa fa-plus"></i>
                            </Link>
                        </span>
                    </div>
                </div >
                <div className="card-body p-4">
                    <div className="mb-3 text-muted card-subtitle"></div>
                    <table className="table table-sm table-hover ">
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Cédula</th>
                                <th style={{ width: "15%" }}>Nombres</th>
                                <th style={{ width: "15%" }}>Apellidos</th>
                                <th style={{ width: "10%" }}>Grupo Sanguíneo</th>
                                <th style={{ width: "5%" }}>RH</th>
                                <th style={{ width: "15%" }}>Sexo</th>
                                <th style={{ width: "15%" }}>Fecha de nacimiento</th>
                                <th style={{ width: "10%" }}>Fecha Último Periodo</th>
                                <th style={{ width: "10%" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                arregloJugadora.map((jugadora, indice) => (
                                    <tr key={indice}>
                                        <td>{jugadora.cedula}</td>
                                        <td>{jugadora.nombresJugadora}</td>
                                        <td>{jugadora.apellidosJugadora}</td>
                                        <td>{jugadora.grupoSanguineo}</td>
                                        <td>{jugadora.rh}</td>
                                        <td>{jugadora.sexo}</td>
                                        <td>{jugadora.fechaNacimiento.toLocaleString()}</td>
                                        <td>{jugadora.fechaUltimoPeriodo.toLocaleString()}</td>
                                        <td>
                                            <Link to={`/dash/playersEdit/${jugadora.cedula}`}>
                                                <i className="fa fa-edit azulito"></i>
                                            </Link>
                                            &nbsp;
                                            <a href="" className="text-decoration-none" onClick={(e) => {
                                                e.preventDefault();
                                                setShow(true);
                                                setJugadoraSeleccionada(jugadora)
                                            }}>
                                                <i className="fa fa-trash-alt rojito"></i>
                                            </a>
                                        </td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                    {/* Inicio: ventana modal */}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Eliminar jugadora</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            ¿Está seguro de que quiere eliminar la jugadora 
                            <strong>{" "+jugadoraSeleccionada.nombresJugadora + " " + jugadoraSeleccionada.apellidosJugadora}</strong> ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={(e) => {
                                e.preventDefault();
                                eliminarJugadora(jugadoraSeleccionada.cedula);
                                setShow(false);
                            }}>Eliminar</Button>
                            <Button variant="dark" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* Fin: ventana modal */}

                </div>
            </div>
        </div>

    );
}