class Players {

    public cedula: number;
    public nombresJugadora: string;
    public apellidosJugadora: string;
    public grupoSanguineo: string;
    public rh: string;
    public sexo: string;
    public fechaNacimiento: string;
    public fechaUltimoPeriodo: string;

    constructor(ced: number, nombres: string, apellidos: string, 
        grupoSan: string, rh: string, sexo: string, fechaNac: string,
        fechaPer: string){
            this.cedula = ced;
            this.nombresJugadora = nombres;
            this.apellidosJugadora = apellidos;
            this.grupoSanguineo = grupoSan;
            this.rh = rh;
            this.sexo = sexo;
            this.fechaNacimiento = fechaNac;
            this.fechaUltimoPeriodo = fechaPer;
        }

}

export default Players;
