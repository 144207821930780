import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Sesion } from "../components/public/sesion";
import { Registro } from "../components/public/registro";
import { Error } from "../components/shared/error";
import { TableroPrincipal } from "../contenedor/tableroPrincipal";
import { Vigilante } from "../seguridad/vigilante";


const LazySesion = lazy(() => import("../components/public/sesion")
.then(() => ({ default: Sesion })));
const LazyRegistro = lazy(() => import("../components/public/registro")
.then(() => ({ default: Registro })));
const LazyError = lazy(() => import("../components/shared/error")
.then(() => ({ default: Error })));


const LazyTablero = lazy(() => import("../contenedor/tableroPrincipal")
.then(() => ({ default: TableroPrincipal })));

export const RuteoPrincipal = () => {

    return (

        <Routes>
            <Route path="/login" element={<LazySesion />} />
            <Route path="/register" element={<LazyRegistro />} />
            <Route element={<Vigilante />}>
                <Route path="/dash/*" element={<LazyTablero />} />
            </Route>


            {/* Rutas Obligatorias */}
            <Route path="/" element={<LazySesion />} />
            <Route path="*" element={<LazyError />} />
        </Routes>
    );

};