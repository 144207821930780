export const OPCIONES_MENU: {titulo: string; ruta: string; icono: string; children?: [] | undefined}[] = [
    {
        titulo: "Inicio",
        ruta: "/dash",
        icono: "fa fa-home"
    },
    {
        titulo: "Jugadoras",
        ruta: "/dash/playersAdmin",
        icono: "fa fa-map",
    },
    {
        titulo: "Antropometría",
        ruta: "/dash/antroAdmin",
        icono: "fa fa-map",
    },
    {
        titulo: "Técnica",
        ruta: "/dash/tecnicaAdmin",
        icono: "fa fa-map",
    },
    {
        titulo: "Físico",
        ruta: "/dash/fisicoAdmin",
        icono: "fa fa-map",
    },
    {
        titulo: "Análisis gráfico",
        ruta: "/dash/grafAdmin",
        icono: "fa fa-map",
    },
]