import { jwtDecode } from "jwt-decode";
import DatoSesion from "../models/datoSesion";

export const Bienvenida = ()=> {

    const token: any = localStorage.getItem("TOKEN_AUTORIZACION");
    const objRecibido: any = jwtDecode(token);

    const datosSesion = new DatoSesion (
        objRecibido.id, 
        objRecibido.nombresUsuario, 
        objRecibido.apellidosUsuario, 
        objRecibido.nombreAcceso
    )
    return (

        <div className="mt-3 ">
            <div className="d-flex justify-content-center">
                <p>Bienvenido { datosSesion.nombresUsuario + " "+datosSesion.apellidosUsuario}</p>
            </div>
        </div>
    )
}