import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import Players from "../../../models/players";
import DatePicker from "react-date-picker";
import moment from 'moment';

type fecha = Date | any;

export const JugadoraActualizar = () => {

    type frmHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);
    const [terminado, setTerminado] = useState<boolean>(false);

    const parametro = useParams();

    let { cedula, nombresJugadora, apellidosJugadora, grupoSanguineo, rh, sexo, fechaNacimiento, fechaUltimoPeriodo, dobleEnlace, objeto } = useFormulario<Players>
        (new Players(0, "", "", "", "", "", "", ""));

    const [fechasNacimiento, setFechasNacimiento] = useState<fecha>(new Date());
    const [fechasUltimoPeriodo, setFechasUltimoPeriodo] = useState<fecha>(new Date());

    const navegacion = useNavigate();

    const obtenerJugadora = async () => {
        const resultado = await ServicioPrivado.peticionGET(APIBack.UNA_JUGADORA + "/" + parametro.cedula);
        if (resultado) {
            objeto.cedula = resultado.cedula;
            objeto.nombresJugadora = resultado.nombresJugadora;
            objeto.apellidosJugadora = resultado.apellidosJugadora;
            objeto.grupoSanguineo = resultado.grupoSanguineo;
            objeto.rh = resultado.rh;
            objeto.sexo = resultado.sexo;

            // Asignar las fechas obtenidas
            setFechasNacimiento(new Date(resultado.fechaNacimiento.toLocaleString()));
            setFechasUltimoPeriodo(new Date(resultado.fechaUltimoPeriodo.toLocaleString()));

            setTerminado(true);
        }
    }

    const enviarFormulario = async (formulario: frmHtml) => {
        formulario.preventDefault();
        setEnProceso(true);
        const miFrm = formulario.currentTarget;
        miFrm.classList.add("was-validated");
        if (miFrm.checkValidity() === false) {
            formulario.preventDefault();
            formulario.stopPropagation();
        } else {
            objeto.fechaNacimiento = fechasNacimiento;
            objeto.fechaUltimoPeriodo = fechasUltimoPeriodo;
            objeto.cedula = Number(parametro.cedula);
            const resultado = await ServicioPrivado.peticionPUT(APIBack.EDITAR_JUGADORA, objeto);
            if (resultado) {
                setEnProceso(false);
                crearMensaje("success", "Los datos de la jugadora se actualizaron con éxito");
                navegacion("/dash/playersAdmin");

            } else {
                crearMensaje("Error", "Fallo al actualizar jugadora");
                obtenerJugadora();
            }
            setEnProceso(false);
        }
    }

    useEffect(() => {
        obtenerJugadora();
    }, [])

    return (

        <div>
            {
                terminado ?
                    <div>
                        <h5 className="text-capitalize  fst-italic fw-bolder"> Actualizar</h5>
                        <nav className="" aria-label="breadcrumb">
                            <ol className="breadcrumb fs-6">
                                <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Incio</Link>
                                <li className="active breadcrumb-item" aria-current="page">Jugadora</li>
                                <Link to="dash/playersAdmin" className="text-decoration-none link-info breadcrumb-item">Administrar</Link>
                                <li className="active breadcrumb-item" aria-current="page">Actualizar</li>
                            </ol>
                        </nav>

                        <div className="m-4 card">
                            <div className="bg-light card-body"><h4 className="mb-0 card-title">Información Jugadora</h4></div>
                            <div className="card-body">
                                <Form validated={enProceso} onSubmit={enviarFormulario}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="cedula">
                                                <Form.Label className="form-label">
                                                    <span className="rojito">*</span>Cédula
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="cedula"
                                                    value={cedula}
                                                    onChange={dobleEnlace}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="nombresJugadora">
                                                <Form.Label className="form-label">
                                                    <span className="rojito">*</span>Nombres
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="nombresJugadora"
                                                    value={nombresJugadora}
                                                    onChange={dobleEnlace}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="apellidosJugadora">
                                                <Form.Label className="form-label">
                                                    <span className="rojito">*</span>Apellidos
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="apellidosJugadora"
                                                    value={apellidosJugadora}
                                                    onChange={dobleEnlace}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="grupoSanguineo">
                                                <Form.Label className="form-label">
                                                    <span className="rojito">*</span>Grupo Sanguineo
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="grupoSanguineo"
                                                    value={grupoSanguineo}
                                                    onChange={dobleEnlace}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="rh">
                                                <Form.Label className="form-label">
                                                    <span className="rojito">*</span>RH
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="rh"
                                                    value={rh}
                                                    onChange={dobleEnlace}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="sexo">
                                                <Form.Label className="form-label">
                                                    <span className="rojito">*</span>Sexo
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="sexo"
                                                    value={sexo}
                                                    onChange={dobleEnlace}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3">
                                            <Form.Group controlId="fechasNacimiento">
                                                <Form.Label><span className="rojito">*</span>Fecha Nacimiento</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    value={fechasNacimiento}
                                                    onChange={date => setFechasNacimiento(date)}
                                                    format="yyyy/MM/dd"
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3">
                                            <Form.Group controlId="fechasUltimoPeriodo">
                                                <Form.Label><span className="rojito">*</span>Fecha Ultimo Periodo</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    value={fechasUltimoPeriodo}
                                                    onChange={date => setFechasUltimoPeriodo(date)}
                                                    format="yyyy/MM/dd"
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="border-top gap-2 d-flex align-items-center card-body ">
                                            <button type="submit" className="btn btn-primary">Actualizar</button>
                                            <button type="button" className="btn btn-danger ml-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    /* regresar Admin o impiar la caja */
                                                    navegacion("/dash/playersAdmin");
                                                }}
                                            >Cancelar</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="mt-5">
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary">
                                <br />
                                <span>Cargando...</span>
                            </div>
                        </div>
                    </div>
            }
        </div>

    );
}