import { useState } from "react";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { Link } from "react-router-dom";
import { Form, Button, Tabs, Tab } from "react-bootstrap";
import Players from "../../../models/players";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import Plot from "react-plotly.js";

export const AnalisisYGraficas = () => {
  const [cedulaBuscada, setCedulaBuscada] = useState<string>("");
  const [jugadoraSeleccionada, setJugadoraSeleccionada] =
    useState<Players | null>(null);
  const [resultadoAnalisis, setResultadoAnalisis] = useState<{
    aptitud: string;
    lesion: string;
  } | null>(null);
  const [graficas, setGraficas] = useState<any>(null);
  // Función para buscar la jugadora por cédula
  const buscarJugadorPorCedula = async (cedula: string) => {
    try {
      const respuesta = await ServicioPrivado.peticionGET(
        APIBack.UNA_JUGADORA + "/" + cedula,
      );
      if (respuesta) {
        setJugadoraSeleccionada(respuesta);
      } else {
        crearMensaje("error", "No se encontró información de la jugadora.");
      }
    } catch (error) {
      console.error("Error al obtener la información de la jugadora", error);
      crearMensaje("error", "Error al buscar los datos de la jugadora.");
    }
  };

  // Función para buscar análisis y gráficas
  const buscarAnalisisYGraficas = async () => {
    try {
      const respuesta = await ServicioPrivado.peticionPOST(
        APIBack.VER_GRAFICAS,
        { cedula: cedulaBuscada },
      );

      setGraficas(respuesta.graficas);
      setResultadoAnalisis({
        aptitud: respuesta.predicciones.aptitud,
        lesion: respuesta.predicciones.lesion,
      });

      buscarJugadorPorCedula(cedulaBuscada);
    } catch (error) {
      crearMensaje("error", "Error al obtener análisis y gráficas");
    }
  };

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb fs-6">
          <Link
            className="text-decoration-none link-info fw-normal breadcrumb-item"
            to="/dash"
          >
            Inicio
          </Link>
          <li className="active breadcrumb-item" aria-current="page">
            Análisis y Gráficas
          </li>
          <li className="active breadcrumb-item" aria-current="page">
            Ver
          </li>
        </ol>
      </nav>
      <div className="card">
        <div className="px-4 py-3 mb-0">
          <div className="row border-bottom">
            <span className="col-md-6 fs-4 card-title">
              Mostrar análisis de datos y gráficas
            </span>
          </div>
        </div>
        <div className="card-body p-4">
          {/* Campo para buscar por cédula */}
          <div className="mb-0">
            <Form>
              <Form.Group className="mb-2" controlId="cedulaBuscada">
                <Form.Control
                  type="text"
                  placeholder="Número de documento"
                  value={cedulaBuscada}
                  onChange={(e) => setCedulaBuscada(e.target.value)}
                />
              </Form.Group>
              <Button onClick={buscarAnalisisYGraficas}>Buscar</Button>
            </Form>
          </div>

          {/* Mostrar información de la jugadora */}
          {jugadoraSeleccionada && (
            <div className="mt-4">
              <h4>Información de la Jugadora</h4>
              <p>
                <strong>Nombre:</strong> {jugadoraSeleccionada.nombresJugadora}{" "}
                {jugadoraSeleccionada.apellidosJugadora}
              </p>
              <p>
                <strong>Cédula:</strong> {jugadoraSeleccionada.cedula}
              </p>
              <p>
                <strong>Último Período:</strong>{" "}
                {jugadoraSeleccionada.fechaUltimoPeriodo}
              </p>
              <p>
                <strong>Fecha de Nacimiento:</strong>{" "}
                {jugadoraSeleccionada.fechaNacimiento}
              </p>
            </div>
          )}

          {/* Mostrar resultado de aptitud y lesión */}
          {resultadoAnalisis && (
            <div className="mt-4">
              <h4>Resultado de Aptitud para Competencia</h4>
              <p>{resultadoAnalisis.aptitud}</p>

              <h4>Probabilidad de Lesión</h4>
              <p>{resultadoAnalisis.lesion}</p>
            </div>
          )}

          {/* Gráficas */}
          {graficas && (
            <div className="mt-4">
              <Tabs defaultActiveKey="antropometria" className="mb-3">
                <Tab eventKey="antropometria" title="Antropometría">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <Plot
                        data={graficas.peso.data}
                        layout={{
                          ...graficas.peso.layout,
                          title: "Evolución del Peso",
                          height: 400,
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <Plot
                        data={graficas.altura.data}
                        layout={{
                          ...graficas.altura.layout,
                          title: "Evolución de la Altura",
                          height: 400,
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <Plot
                        data={graficas.cadera.data}
                        layout={{
                          ...graficas.cadera.layout,
                          title: "Evolución de la Cadera",
                          height: 400,
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <Plot
                        data={graficas.anguloQ.data}
                        layout={{
                          ...graficas.anguloQ.layout,
                          title: "Evolución del Ángulo Q",
                          height: 400,
                        }}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="tecnico" title="Rendimiento Técnico">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <Plot
                        data={graficas.tecnico.data}
                        layout={{
                          ...graficas.tecnico.layout,
                          title: "Evolución del Rendimiento Técnico",
                          height: 500,
                        }}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="fisico" title="Rendimiento Físico">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <Plot
                        data={graficas.fisico.data}
                        layout={{
                          ...graficas.fisico.layout,
                          title: "Evolución del Rendimiento Físico",
                          height: 500,
                        }}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="riesgo" title="Análisis de Riesgo">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <Plot
                        data={graficas.lesion.data}
                        layout={{
                          ...graficas.lesion.layout,
                          title: "Probabilidad de Lesión",
                          height: 500,
                        }}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
