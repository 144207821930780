import React, { useEffect, useState } from "react";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";
import Players from "../../../models/players";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import Physical from "../../../models/physical";

export const FisicoAdministrar = () => {

    const [arregloFis, setArregloFis] = useState<Physical[]>([]);
    const [cedulaBuscada, setCedulaBuscada] = useState<string>("");
    const [fisicoSeleccionado, setFisicoSeleccionado] = useState<Physical | null>(null);
    const [jugadoraSeleccionada, setJugadoraSeleccionada] = useState<Players | null>(null);
    const [show, setShow] = useState(false);
    const [mensaje, setMensaje] = useState("");

    const handleClose = () => setShow(false);
    const navegacion = useNavigate();

    const buscarJugadorPorCedula = async (cedula: string) => {
        try {
            const respuesta = await ServicioPrivado.peticionGET(APIBack.UNA_JUGADORA + "/" + cedula);
            if (respuesta) {
                setJugadoraSeleccionada(respuesta);
            } else {
                crearMensaje("error", "No se encontró información de la jugadora.");
            }
        } catch (error) {
            console.error("Error al obtener la información de la jugadora", error);
            crearMensaje("error", "Error al buscar los datos de la jugadora.");
        }
    };

    const buscarFisico = async () => {
        try {
            const respuesta = await ServicioPrivado.peticionGET(APIBack.UNA_FISICO + "/" + cedulaBuscada);
            if (Array.isArray(respuesta) && respuesta.length > 0) {
                setArregloFis(respuesta);
                setShow(true);
                setMensaje(""); // Limpiar el mensaje
                buscarJugadorPorCedula(cedulaBuscada);
            } else {
                crearMensaje("error", "No hay formularios asociados a este número de documento.");
                setMensaje("No hay formularios asociados a este número de documento.");
                setArregloFis([]);
            }
        } catch (error) {
            crearMensaje("error", "Error al buscar los datos.");
        }
    };

    useEffect(() => {
        if (arregloFis.length > 0) {
            // Aquí
        }
    }, [arregloFis]);

    const formatNumber = (value: number | undefined, decimals: number = 2): string => {
        if (value !== undefined && value !== null) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(value);
        }
        return 'No disponible';
    };

    const handleAddNewToma = () => {
        if (jugadoraSeleccionada) {
            const cedula = jugadoraSeleccionada.cedula;
            navegacion(`/dash/fisicoAdd?cedula=${cedula}`);
        }
    };

    return (
        <div className="">
            <h5 className="text-capitalize fst-italic fw-bolder">Administrar</h5>
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash"> Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Físico</li>
                    <li className="active breadcrumb-item" aria-current="page">Administrar</li>
                </ol>
            </nav>
            <div className="card">
                <div className="px-4 py-3 mb-0">
                    <div className="row border-bottom">
                        <span className="col-md-6 fs-4 card-title">Físico - Administrar</span>
                        <span className="col-md-6 text-end"></span>
                    </div>
                </div>
                <div className="card-body p-4">
                    {/* Campo para buscar por cédula */}
                    <div className="mb-0">
                        <Form>
                            <Form.Group className="mb-2" controlId="cedulaBuscada">
                                <Form.Control
                                    type="text"
                                    placeholder="Número de documento"
                                    value={cedulaBuscada}
                                    onChange={(e) => setCedulaBuscada(e.target.value)}
                                />
                            </Form.Group>
                            <Button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={buscarFisico}
                            >
                                Buscar
                            </Button>
                        </Form>
                    </div>

                    {/* Mensaje de error */}
                    {mensaje && (
                        <div className="alert alert-warning">
                            {mensaje}
                            {mensaje === "No hay formularios asociados a este número de documento." && (
                                <div className="mt-3">
                                    <Link to={`/dash/fisicoAdd?cedula=${cedulaBuscada}`}>
                                        <Button className="btn btn-success">Agregar</Button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="mt-4"></div>
                    {/* Mostrar información de la jugadora */}
                    {jugadoraSeleccionada && (
                        <div className="mb-6">
                            <h4>Información de la Jugadora</h4>
                            <p><strong>Nombre:</strong> {jugadoraSeleccionada.nombresJugadora} {jugadoraSeleccionada.apellidosJugadora}</p>
                            <p><strong>Cédula:</strong> {jugadoraSeleccionada.cedula}</p>
                            <p><strong>Último Período:</strong> {jugadoraSeleccionada.fechaUltimoPeriodo.toLocaleString()}</p>
                            <p><strong>Fecha Nacimiento:</strong> {jugadoraSeleccionada.fechaNacimiento.toLocaleString()}</p>
                        </div>
                    )}

                    {/* Mostrar múltiples formularios de antropometría */}
                    {arregloFis.length > 0 && (
                        <div className="table-container">
                            <table className="table table-bordered table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th>Test</th>
                                        {arregloFis.map((_, index) => (
                                            <th key={index}>Toma {index + 1}</th>
                                        ))}
                                        <th className="text-center">
                                            <button onClick={handleAddNewToma} className="btn btn-success">
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Fila para mostrar las fechas de cada toma */}
                                    <tr>
                                        <th className="table-active text-center">Fecha Toma Físico</th>
                                        {arregloFis.map((item, index) => (
                                            <td key={index}>{item.fechaTomafis.toLocaleString()}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    {/* Fila para mostrar los nombres de los tests */}
                                    <tr>
                                        <th className="table-active text-center">Test de Yo-Yo</th>
                                        {arregloFis.map((item, index) => (
                                            <td key={index}>{item.puntaje}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th className="table-active text-center">Test de Cooper</th>
                                        {arregloFis.map((item, index) => (
                                            <td key={index}>{item.puntajeCooper}</td>
                                        ))}
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}    