import React, { useState } from "react";
import Technique from "../../../models/technique";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import {Link, useLocation, useNavigate } from "react-router-dom";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import DatePicker from "react-date-picker";
import { Form } from "react-bootstrap";

type fecha = Date | any;

export const TecnicaCrear = () => {

    type frmHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);

    let { fechaTomatec, jugadoraCedula, definicion, dominioBalon, efectividadPase, tomaDecisiones, dobleEnlace, objeto } = useFormulario<Technique>
            (new Technique("", "", "", "", "", 0));

    const [fechasTomaTec, setFechasTomaTec] = useState<fecha>(new Date());

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const cedula = queryParams.get('cedula') || '';
    const [jugadorasCedulaAdd, setJugadorasCedulaAdd] = React.useState<string>(cedula);

    const navegacion = useNavigate();

    const [formValues, setFormValues] = useState<Technique>(
        new Technique("", "", "", "", "", 0)
    );

    const enviarFormulario = async (formulario: frmHtml) => {
        formulario.preventDefault();
        setEnProceso(true);
        const miFrm = formulario.currentTarget;
        miFrm.classList.add("was-validated");
        if (miFrm.checkValidity() === false) {
            formulario.preventDefault();
            formulario.stopPropagation();
        } else {
            const fechaTomaTecFormateada = fechasTomaTec.toISOString().slice(0, 10); // YYYY-MM-DD
            objeto.fechaTomatec = fechaTomaTecFormateada;
            objeto.jugadoraCedula = parseInt(jugadorasCedulaAdd);
            
            // Usar los valores del formValues
            objeto.definicion = formValues.definicion;
            objeto.dominioBalon = formValues.dominioBalon;
            objeto.efectividadPase = formValues.efectividadPase;
            objeto.tomaDecisiones = formValues.tomaDecisiones;
    
            const resultado = await ServicioPrivado.peticionPOST(APIBack.AGREGAR_TECNICA, objeto);
            if (resultado) {
                setEnProceso(false);
                crearMensaje("success", "Registro técnico exitoso");
                navegacion("/dash/tecnicaAdmin");
            } else {
                crearMensaje("error", "Fallo al registrar formulario");
                limpiarCajas(miFrm);
            }
            setEnProceso(false);
        }
    };
    

    const limpiarCajas = (formulario: HTMLFormElement) => {
        formulario.reset();
        formulario.jugadoraCedula.value = "";
        formulario.definicion.value = " ";
        formulario.dominioBalon.value = "";
        formulario.efectividadPase.value = "";
        formulario.tomaDecisiones.value = "";
        
        formulario.classList.remove("was-validated");
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    return (
        <div>
            <h5 className="text-capitalize fst-italic fw-bolder">Crear</h5>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Técnica</li>
                    <Link to="dash/tecnicaAdmin" className="text-decoration-none link-info breadcrumb-item">Administrar</Link>
                    <li className="active breadcrumb-item" aria-current="page">Crear</li>
                </ol>
            </nav>

            <div className="m-4 card">
                <div className="bg-light card-body">
                    <h4 className="mb-0 card-title">Información Técnica</h4>
                </div>
                <div className="card-body">
                    <Form validated={enProceso} onSubmit={enviarFormulario}>
                        <div className="row">
                            <div className="mb-3">
                                <Form.Group controlId="fechasTomaTec">
                                    <Form.Label><span className="rojito">*</span>Tomado en</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        value={fechasTomaTec}
                                        onChange={date => setFechasTomaTec(date)}
                                        format="yyyy/MM/dd"
                                        clearIcon={null}
                                        calendarIcon={null}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="jugadorasCedulaAdd">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>C.C Jugadora
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="jugadorasCedulaAdd"
                                        value={jugadorasCedulaAdd}
                                        onChange={(e) => setJugadorasCedulaAdd(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="definicion">
                                    <Form.Label><span className="rojito">*</span>Definición</Form.Label>
                                    <Form.Select
                                        className="form-control"
                                        required
                                        name="definicion"
                                        value={formValues.definicion}
                                        onChange={handleChange}
                                    >
                                        <option value="">Seleccionar...</option>
                                        <option value="Muy malo">Muy malo</option>
                                        <option value="Malo">Malo</option>
                                        <option value="Regular">Regular</option>
                                        <option value="Bueno">Bueno</option>
                                        <option value="Muy bueno">Muy bueno</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="dominioBalon">
                                    <Form.Label><span className="rojito">*</span>Dominio del balón</Form.Label>
                                    <Form.Select
                                        className="form-control"
                                        required
                                        name="dominioBalon"
                                        value={formValues.dominioBalon}
                                        onChange={handleChange}
                                    >
                                        <option value="">Seleccionar...</option>
                                        <option value="Muy malo">Muy malo</option>
                                        <option value="Malo">Malo</option>
                                        <option value="Regular">Regular</option>
                                        <option value="Bueno">Bueno</option>
                                        <option value="Muy bueno">Muy bueno</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="efectividadPase">
                                    <Form.Label><span className="rojito">*</span>Efectividad del pase</Form.Label>
                                    <Form.Select
                                        className="form-control"
                                        required
                                        name="efectividadPase"
                                        value={formValues.efectividadPase}
                                        onChange={handleChange}
                                    >
                                        <option value="">Seleccionar...</option>
                                        <option value="Muy malo">Muy malo</option>
                                        <option value="Malo">Malo</option>
                                        <option value="Regular">Regular</option>
                                        <option value="Bueno">Bueno</option>
                                        <option value="Muy bueno">Muy bueno</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="tomaDecisiones">
                                    <Form.Label><span className="rojito">*</span>Toma de decisiones</Form.Label>
                                    <Form.Select
                                        className="form-control"
                                        required
                                        name="tomaDecisiones"
                                        value={formValues.tomaDecisiones}
                                        onChange={handleChange}
                                    >
                                        <option value="">Seleccionar...</option>
                                        <option value="Muy malo">Muy malo</option>
                                        <option value="Malo">Malo</option>
                                        <option value="Regular">Regular</option>
                                        <option value="Bueno">Bueno</option>
                                        <option value="Muy bueno">Muy bueno</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="border-top gap-2 d-flex align-items-center card-body">
                                <button type="submit" className="btn btn-primary">Registrar</button>
                                <button type="button" className="btn btn-danger ml-2" onClick={(e) => {
                                    e.preventDefault();
                                    navegacion("/dash/tecnicaAdmin");
                                }}>Cancelar</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}