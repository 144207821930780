import React, { useState } from "react";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import Antropometry from "../../../models/antropometry";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import DatePicker from "react-date-picker";

type fecha = Date | any;

export const AntropometriaCrear = () => {

    type frmHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);

    let { fechaTomaAntro, jugadorasCedula, peso, talla, anguloQ: anguloQ, envergadura, trenInferior, subescapular, tricipital, bicipital,
        supracrestal, supraespinal, abdominal, musloAnterior, piernaMedial, brazoRelajado, brazoFlexionado, musloMedial, pantorrilla,
        cintura, cadera, humero, muñeca, femur, dobleEnlace, objeto } = useFormulario<Antropometry>
            (new Antropometry("", 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0));

    const [fechasTomaAntro, setFechasTomaAntro] = useState<fecha>(new Date());

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const cedula = queryParams.get('cedula') || '';
    const [jugadorasCedulaAdd, setJugadorasCedulaAdd] = React.useState<string>(cedula);

    const navegacion = useNavigate();

    const enviarFormulario = async (formulario: frmHtml) => {
        formulario.preventDefault();
        setEnProceso(true);
        const miFrm = formulario.currentTarget;
        miFrm.classList.add("was-validated");
        if (miFrm.checkValidity() === false) {
            formulario.preventDefault();
            formulario.stopPropagation();
        } else {
            const fechaTomaAntroFormateada = fechasTomaAntro.toISOString().slice(0, 10); // YYYY-MM-DD
            objeto.fechaTomaAntro = fechaTomaAntroFormateada;
            objeto.jugadorasCedula = parseInt(jugadorasCedulaAdd);
            const resultado = await ServicioPrivado.peticionPOST(APIBack.AGREGAR_ANTROPOMETRIA, objeto);
            if (resultado) {
                setEnProceso(false);
                crearMensaje("success", "Registro antropométrico exitoso");
                navegacion("/dash/antroAdmin");

            } else {
                crearMensaje("error", "Fallo al registrar antropometría");
                limpiarCajas(miFrm);
            }
            setEnProceso(false);
        }
    }

    const limpiarCajas = (formulario: HTMLFormElement) => {
        formulario.reset();
        formulario.codAntropometria.value = "";
        formulario.jugadorasCedula.value = "";
        formulario.envergadura.value = " ";
        formulario.trenInferior.value = "";
        formulario.subescapular.value = "";
        formulario.tricipital.value = "";
        formulario.bicipital.value = "";
        formulario.supracrestal.value = "";
        formulario.supraespinal.value = "";
        formulario.abdominal.value = "";
        formulario.musloAnteerior.value = "";
        formulario.piernaMedial.value = "";
        formulario.brazoRelajado.value = "";
        formulario.brazoFlexionado.value = "";
        formulario.musloMedial.value = "";
        formulario.pantorrilla.value = "";
        formulario.cintura.value = "";
        formulario.cadera.value = "";
        formulario.humero.value = "";
        formulario.muñeca.value = "";
        formulario.femur.value = "";
        formulario.classList.remove("was-validated");
    }

    return (
        <div>
            <h5 className="text-capitalize fst-italic fw-bolder">Crear</h5>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Antropometría</li>
                    <Link to="dash/antroAdmin" className="text-decoration-none link-info breadcrumb-item">Administrar</Link>
                    <li className="active breadcrumb-item" aria-current="page">Crear</li>
                </ol>
            </nav>

            <div className="m-4 card">
                <div className="bg-light card-body"><h4 className="mb-0 card-title">Información Antropometría</h4></div>
                <div className="card-body">
                    <Form validated={enProceso} onSubmit={enviarFormulario}>
                        <div className="row">
                            <div className="mb-3">
                                <Form.Group controlId="fechasTomaAntro">
                                    <Form.Label><span className="rojito">*</span>Tomado en</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        value={fechasTomaAntro}
                                        onChange={date => setFechasTomaAntro(date)}
                                        format="yyyy/MM/dd"
                                        clearIcon={null}
                                        calendarIcon={null}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="jugadorasCedulaAdd">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>C.C Jugadora
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="jugadorasCedulaAdd"
                                        value={jugadorasCedulaAdd}
                                        onChange={(e) => setJugadorasCedulaAdd(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            {/* Medidas básicas */}
                            <h5 className="mt-4 fs-4 fw-bold">Medidas Básicas</h5>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="peso">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Peso</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="peso"
                                            value={peso}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">kg</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="talla">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Estatura</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="talla"
                                            value={talla}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="anguloQ">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Angulo Q</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="anguloQ"
                                            value={anguloQ}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="envergadura">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Envergadura</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="envergadura"
                                            value={envergadura}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="trenInferior">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Tren Inferior</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="trenInferior"
                                            value={trenInferior}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Pliegues cutáneos */}
                            <h5 className="mt-4 fs-4 fw-bold">Pliegues Cutáneos</h5>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="subescapular">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Subescapular</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="subescapular"
                                            value={subescapular}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="tricipital">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Tricipital</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="tricipital"
                                            value={tricipital}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="bicipital">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Bicipital</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="bicipital"
                                            value={bicipital}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="supracrestal">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Supracrestal</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="supracrestal"
                                            value={supracrestal}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="supraespinal">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Supraespinal</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="supraespinal"
                                            value={supraespinal}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="abdominal">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Abdominal</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="abdominal"
                                            value={abdominal}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="musloAnterior">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Muslo Anterior</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="musloAnterior"
                                            value={musloAnterior}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="piernaMedial">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Pierna Medial</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="piernaMedial"
                                            value={piernaMedial}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Perímetros */}
                            <h5 className="mt-4 fs-4 fw-bold">Perímetros</h5>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="brazoRelajado">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Brazo Relajado</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="brazoRelajado"
                                            value={brazoRelajado}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="brazoFlexionado">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Brazo Flexionado</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="brazoFlexionado"
                                            value={brazoFlexionado}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="musloMedial">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Muslo Medial</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="musloMedial"
                                            value={musloMedial}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="pantorrilla">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Pantorrilla</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="pantorrilla"
                                            value={pantorrilla}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="cintura">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Cintura</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="cintura"
                                            value={cintura}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="cadera">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Cadera</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="cadera"
                                            value={cadera}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Diámetros */}
                            <h5 className="mt-4 fs-4 fw-bold">Diámetros</h5>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="humero">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Húmero</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="humero"
                                            value={humero}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="muñeca">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Muñeca</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="muñeca"
                                            value={muñeca}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="femur">
                                    <Form.Label className="form-label"><span className="rojito">*</span>Fémur</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            required
                                            name="femur"
                                            value={femur}
                                            onChange={dobleEnlace}
                                        />
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </Form.Group>
                            </div>

                            <div className="border-top gap-2 d-flex align-items-center card-body">
                                <button type="submit" className="btn btn-primary">Registrar</button>
                                <button type="button" className="btn btn-danger ml-2" onClick={(e) => {
                                    e.preventDefault();
                                    navegacion("/dash/antroAdmin");
                                }}>Cancelar</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );

}

