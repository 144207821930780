class Antropometry{

    public fechaTomaAntro: string;
    public jugadorasCedula: number;
    public peso: number;
    public talla: number;
    public anguloQ: number;
    public envergadura: number;
    public trenInferior: number;
    public subescapular: number;
    public tricipital: number;
    public bicipital: number;
    public supracrestal: number;
    public supraespinal: number;
    public abdominal: number;
    public musloAnterior: number;
    public piernaMedial: number;
    public brazoRelajado: number;
    public brazoFlexionado: number;
    public musloMedial: number;
    public pantorrilla: number;
    public cintura: number;
    public cadera: number;
    public humero: number;
    public muñeca: number;
    public femur: number;

    constructor(fechaToma: string, peso: number, talla: number, anguloQ: number, envergadura: number, trenInf: number,
        subescapular:number, tricipital:number, bicipital: number, supracrestal: number, 
        supraespinal: number, abdominal: number, musloAnterior: number, piernaMedial:number,
        brazoRelajado: number, brazoFlexionado: number, musloMedial:number, pantorrilla: number,
        cintura:number, cadera: number, humero: number, muñeca: number, femur: number, jugadorasCed: number){
            
            this.fechaTomaAntro = fechaToma;
            this.peso = peso;
            this.talla = talla;
            this.anguloQ = anguloQ;
            this.envergadura = envergadura;
            this.trenInferior = trenInf;
            this.subescapular = subescapular;
            this.tricipital = tricipital;
            this.bicipital = bicipital;
            this.supracrestal = supracrestal;
            this.supraespinal = supraespinal;
            this.abdominal = abdominal;
            this.musloAnterior = musloAnterior;
            this.piernaMedial = piernaMedial;
            this.brazoRelajado = brazoRelajado;
            this.brazoFlexionado = brazoFlexionado;
            this.musloMedial = musloMedial;
            this.pantorrilla = pantorrilla;
            this.cintura = cintura;
            this.cadera = cadera;
            this.humero = humero;
            this.muñeca = muñeca;
            this.femur = femur;
            this.jugadorasCedula = jugadorasCed;
        }

}
export default Antropometry;