import { Button, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand } from "reactstrap"
import {ReactComponent as LogoWhite} from '../../assets/images/logos/adminprowhite.svg';
import {ReactComponent as LogoDark} from '../../assets/images/logos/logo.svg.svg';
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import user from '../../assets/images/users/user3.jpg';
import { jwtDecode } from "jwt-decode";

export const MenuSuperior =() =>{

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const navegation = useNavigate();

    const toggle = () => setDropdownOpen((prevState)=> !prevState);
    const Handletoggle = () => setIsOpen(!isOpen);
    const showMobilemenu = () =>{
        document.getElementById("sidebarArea")?.classList.toggle("showSidebar")
    }

    const token: any = localStorage.getItem("TOKEN_AUTORIZACION");
    const objRecibed: any = jwtDecode(token);

    const logOut = (event: MouseEvent<HTMLElement>) =>{
        event.preventDefault();
        localStorage.removeItem("TOKEN_AUTORIZACION");
        navegation("/login");
    }

    return(
        <Navbar color="white" light expand="md" className="fix-header">
        <div className="d-flex align-items-center">
            <div className="d-lg-block d-none me-5 pe-3">
                <LogoDark />
            </div>
            <NavbarBrand href="/">
                <LogoWhite className="d-lg-none" />
            </NavbarBrand>
            <Button
                color="secondary"
                className=" d-lg-none"
                onClick={() => showMobilemenu()}
            >
               <i className="fa-solid fa-ellipsis"></i>
            </Button>
        </div>
        <div className="hstack gap-2">
            <Button
                color="secondary"
                size="sm"
                className="d-sm-block d-md-none"
                onClick={Handletoggle}
            >
                {isOpen ? (
                    <i className="bi bi-x"></i>
                ) : (
                    <i className="fa-solid fa-user"></i>
                )}
            </Button>
        </div>

        <Collapse navbar isOpen={isOpen}>
            <Nav className="navbar-nav me-auto" navbar/>
            <span className="d-flex justify-content-center">

                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color="transparent">
                        <img
                            src={user}
                            alt="profile"
                            className="rounded-circle"
                            width="30"
                        ></img>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>{objRecibed.nameUser+"  "+objRecibed.lastNameUser}</DropdownItem>
                        <DropdownItem>Perfil</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={logOut}>Cerrar sesión</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </span>
        </Collapse>
    </Navbar >
    )
}