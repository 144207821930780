import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import ServicioPrivado from "../../../services/servicioPrivado";
import { APIBack } from "../../../utilities/dominios/apiBack";
import { crearMensaje } from "../../../utilities/funciones/mensajeToasty";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import Players from "../../../models/players";

type fecha = Date | any;

export const JugadoraCrear = () => {

    type frmHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);

    let { cedula, nombresJugadora, apellidosJugadora, grupoSanguineo, rh, sexo, fechaNacimiento, fechaUltimoPeriodo, dobleEnlace, objeto } = useFormulario<Players>(
        new Players(0, "", "", "", "", "", "", "")
    );

    const [fechasNacimiento, setFechasNacimiento] = useState<fecha>(new Date());
    const [fechasUltimoPeriodo, setFechasUltimoPeriodo] = useState<fecha>(new Date());

    const navegacion = useNavigate();

    const enviarFormulario = async (formulario: frmHtml) => {
        formulario.preventDefault();
        setEnProceso(true);
        const miFrm = formulario.currentTarget;
        miFrm.classList.add("was-validated");
    
        if (miFrm.checkValidity() === false) {
            formulario.preventDefault();
            formulario.stopPropagation();
        } else {
            // Convertimos la fecha sin desplazamiento de zona horaria y la mantenemos como string
            const fechaNacimientoFormateada = new Date(fechasNacimiento.getTime() - (fechasNacimiento.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
            objeto.fechaNacimiento = fechaNacimientoFormateada;
    
            const fechaUltimoPeriodoFormateada = new Date(fechasUltimoPeriodo.getTime() - (fechasUltimoPeriodo.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
            objeto.fechaUltimoPeriodo = fechaUltimoPeriodoFormateada;
    
            const resultado = await ServicioPrivado.peticionPOST(APIBack.AGREGAR_JUGADORA, objeto);
            if (resultado) {
                setEnProceso(false);
                crearMensaje("success", "Jugadora registrada con éxito");
                navegacion("/dash/playersAdmin");
            } else {
                crearMensaje("Error", "Fallo al registrar jugadora");
                limpiarCajas(miFrm);
            }
            setEnProceso(false);
        }
    }
    

    const limpiarCajas = (formulario: HTMLFormElement) => {
        formulario.reset();
        formulario.cedula.value = 0;
        formulario.classList.remove("was-validated");
    }

    return (
        <div>
            <h5 className="text-capitalize fst-italic fw-bolder">Crear</h5>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Inicio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Jugadora</li>
                    <Link to="/dash/playersAdmin" className="text-decoration-none link-info breadcrumb-item">Administrar</Link>
                    <li className="active breadcrumb-item" aria-current="page">Crear</li>
                </ol>
            </nav>

            <div className="m-4 card">
                <div className="bg-light card-body">
                    <h4 className="mb-0 card-title">Información Jugadora</h4>
                </div>
                <div className="card-body">
                    <Form validated={enProceso} onSubmit={enviarFormulario}>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="cedula">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>Cédula
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="cedula"
                                        value={cedula}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="nombresJugadora">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>Nombres
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="nombresJugadora"
                                        value={nombresJugadora}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="apellidosJugadora">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>Apellidos
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="apellidosJugadora"
                                        value={apellidosJugadora}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="grupoSanguineo">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>Grupo Sanguineo
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="grupoSanguineo"
                                        value={grupoSanguineo}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="rh">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>RH
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="rh"
                                        value={rh}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="sexo">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>Sexo
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        required
                                        name="sexo"
                                        value={sexo}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>
                            </div>
                            <div className="mb-3">
                                <Form.Group controlId="fechasNacimiento">
                                    <Form.Label><span className="rojito">*</span>Fecha Nacimiento</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        value={fechasNacimiento}
                                        onChange={date => setFechasNacimiento(date)}
                                        format="yyyy-MM-dd"
                                        clearIcon={null}
                                        calendarIcon={null}
                                    />
                                </Form.Group>
                            </div>
                            <div className="mb-3">
                                <Form.Group controlId="fechasUltimoPeriodo">
                                    <Form.Label><span className="rojito">*</span>Fecha Ultimo Periodo</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        value={fechasUltimoPeriodo}
                                        onChange={date => setFechasUltimoPeriodo(date)}
                                        format="yyyy-MM-dd"
                                        clearIcon={null}
                                        calendarIcon={null}
                                    />
                                </Form.Group>
                            </div>
                            <div className="border-top gap-2 d-flex align-items-center card-body ">
                                <button type="submit" className="btn btn-primary">Registrar</button>
                                <button type="button" className="btn btn-danger ml-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navegacion("/dash/playersAdmin");
                                    }}
                                >Cancelar</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
