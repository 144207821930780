import { RuteoInterno } from "../routes/ruteoInterno"
import { MenuLateral } from "./menuLateral"
import { MenuSuperior } from "./menuSuperior"

export const TableroPrincipal = () => {
    return (
       <main>
            {/* INICIO: Menu superior */}
            <MenuSuperior/>

            {/* FIN: MenuSuperior */}

            {/* INICIO: MenuLateral */}
            <div className="d-lg-flex pageWrapper">
                <aside className="sidebarArea shadow" id= "sidebarArea">
                    <MenuLateral/>
                </aside>
                {/* INICIO: Contendo */}
                    <div className="contentArea">
                        <RuteoInterno/>
                    </div>
                    {/* FIN: Contendo */}
            </div>
            {/* FIN: MenuLateral */}

       </main>
    )
}